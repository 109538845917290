import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, forkJoin, from, Observable, of, shareReplay } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { arrayDiff, arrayDiffIdCompare, isDefined, isUndefined, tapSubscribed } from '../utils';
import { FileCacheModel, Menu, MenuRaw } from '../models';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { WatchdogService } from './watchdog.service';
import { Intercom } from './intercom.service';
import { RestaurantTableService } from './restaurant-table.service';
import { WebsocketService } from './websocket.service';
import { MenuRepository } from '../repositories/menu.repository';
import { FileCacheRepository } from '../repositories/file-cache.repository';
import { hasMany, hasOne } from '../database/repository.operators';
import { ClusterService } from './cluster.service';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

@Injectable()
export class MenusService {

  public readonly loading$ = new BehaviorSubject<boolean>(true);
  public readonly sync$ = new BehaviorSubject<boolean>(false);
  private readonly logger = this.watchdog.tag('Menus', 'green');

  public readonly menus$ = this.menuRepository.all$().pipe(
    tap(() => {
      this.loading$.next(true);
    }),
    shareReplay(1),
  );

  public readonly menusWithIcon$ = this.menus$.pipe(
    tapSubscribed(() => {
      this.loading$.next(true);
    }),
    hasOne(
      this.filesCacheRepository,
      'url',
      'icon',
      'iconLocal',
    ),
    map((menus) => {
      if (menus === undefined) {
        return undefined;
      }

      return (Array.isArray(menus) ? menus : [menus]).map(menu => {
        const iconFile = menu.iconLocal
          ? new FileCacheModel(menu.iconLocal)
          : undefined;

        const iconLocal = iconFile
          ? this.domSanitizer.bypassSecurityTrustUrl(iconFile.objectUrl)
          : undefined;

        return {
          ...menu,
          iconLocal
        };
      });
    }),
    tap(() =>{
      this.loading$.next(false);
    }),
    shareReplay(1)
  );

  public readonly menusWithPages$ = this.menus$.pipe(
    tapSubscribed(() => {
      this.loading$.next(true);
    }),
    hasMany(
      this.filesCacheRepository,
      'url',
      'pages',
      'pagesLocal',
    ),
    map((menus) => {
      if (menus === undefined) {
        return undefined;
      }

      return (Array.isArray(menus) ? menus : [menus]).map(menu => {
        const pagesLocal = menu.pagesLocal.map((page) => {
          const pageFile = page ? new FileCacheModel(page) : undefined;
          return pageFile
            ? this.domSanitizer.bypassSecurityTrustUrl(pageFile.objectUrl)
            : undefined;
        });

        return {
          ...menu,
          pagesLocal: pagesLocal.filter(isDefined),
        };
      });
    }),
    tap(() => this.loading$.next(false)),
    shareReplay(1)
  );

  public constructor(
    private readonly destroyRef: DestroyRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly auth: AuthService,
    private readonly watchdog: WatchdogService,
    private readonly cluster: ClusterService,
    private readonly filesCache: FileCacheService,
    private readonly menuRepository: MenuRepository,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly intercom: Intercom,
    private readonly restaurantTable: RestaurantTableService,
    private readonly webSocket: WebsocketService,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

    // Clear menus on logout
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared menus on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Listen websocket messages
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'tableInfo'),
      switchMap((response) => {
        if (response.data.menus) {
          return this.sync(response.data.menus);
        } else {
          return this.clear();
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public getMenuWithPages(id: number): Observable<Menu | undefined> {
    return this.menuRepository.one$(id).pipe(
      tapSubscribed(() => {
        this.loading$.next(true);
      }),
      hasMany(
        this.filesCacheRepository,
        'url',
        'pages',
        'pagesLocal',
      ),
      tap(() => this.logger.info('process Menu WithPages')),
      map((menu) => {
        if (!menu) {
          return undefined;
        }

        const pagesLocal =
          (
            Array.isArray(menu) ? menu[0] : menu
          ).pagesLocal.map((page) => {
            const pageFile = page ? new FileCacheModel(page) : undefined;
            return pageFile
              ? this.domSanitizer.bypassSecurityTrustUrl(pageFile.objectUrl)
              : undefined;
          });

        return {
          ...(
            Array.isArray(menu) ? menu[0] : menu
          ),
          pagesLocal: pagesLocal.filter(isDefined),
        };
      }),
      tap(() => {
        this.loading$.next(false);
      }),
    );
  }

  public getMenuForCurrentMedia(): Observable<Menu | null> {
    return combineLatest([
      this.intercom.currentMedia$,
      this.restaurantTable.table$,
    ]).pipe(
      switchMap(([currentMedia, table]) => {
        if (!table || !currentMedia) {
          return of(null);
        }

        const media = table.clickableMediaConfig?.find((media) => {
          return media.id === currentMedia.id;
        });

        if (!media || !media.menuId) {
          return of(null);
        }

        return this.getMenuWithPages(media.menuId);
      }),
      map((menu) => menu ? menu : null),
      take(1),
    );
  }

  public clear(): Observable<string[] | null> {
    return this.menuRepository.all$().pipe(
      take(1),
      switchMap((menus) => {
        if (isUndefined(menus)) {
          return of(menus);
        }

        const urls = menus.map((menu: MenuRaw) => {
          return menu.pages;
        }).flat();

        return forkJoin(urls.map((url) => {
          return this.filesCacheRepository.delete$(url).pipe(
            map(() => url),
          );
        }));
      }),
      switchMap((urls) => {
        return this.menuRepository.clear$().pipe(
          map(() => urls),
        );
      })
    );
  }

  public sync(newMenus: MenuRaw[]) {
    return this.menuRepository.all$().pipe(
      take(1),
      tapSubscribed(() => {
        this.sync$.next(true);
      }),
      map((entry) => {
        const currentMenus = entry ?? [];

        return {
          add: arrayDiff(newMenus, currentMenus, arrayDiffIdCompare),
          update: currentMenus.reduce<ToUpdate<MenuRaw>[]>((acc, origin) => {
            const value = newMenus.find((menu) => menu.id === origin.id);
            if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
              acc.push({ origin, value });
            }
            return acc;
          }, []),
          delete: arrayDiff(currentMenus, newMenus, arrayDiffIdCompare),
        };
      }),
      filter((changed) => {
        if (
          changed.add.length === 0 &&
          changed.update.length === 0 &&
          changed.delete.length === 0
        ) {
          this.sync$.next(false);
          return false;
        }

        return true;
      }),
      switchMap((changed) => forkJoin([
        this.toAdd(changed.add),
        this.toUpdate(changed.update),
        this.toDelete(changed.delete),
      ])),
      tap(() => {
        this.sync$.next(false);
      }),
    );
  }

  private toAdd(menus: MenuRaw[]): Observable<MenuRaw[]> {
    if (menus.length === 0) {
      return of([]);
    }

    const menuFilesDownload$ = (menu: MenuRaw) => {
      const downloadMenuPages$ = this.filesCache.downloadFiles(menu.pages).pipe(
        tap(files => this.logger.debug(`Downloaded pages for menu ${ menu.id }`, files)),
      );

      if (menu.icon) {
        const downloadMenuIcon$ = this.filesCache.downloadFile(menu.icon).pipe(
          tap(file => this.logger.debug(`Downloaded icon for menu ${ menu.id }`, file)),
        );

        return forkJoin([
          downloadMenuPages$,
          downloadMenuIcon$,
        ]).pipe(
          switchMap(() => this.menuRepository.add$(menu)),
          map(() => menu)
        );
      }

      return downloadMenuPages$.pipe(
        switchMap(() => this.menuRepository.add$(menu)),
        map(() => menu),
      );
    };

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to add', menus)),
      switchMap((menus) => forkJoin(
        menus.map((menu) => menuFilesDownload$(menu)),
      )),
      tap((menus) => this.logger.debug('Menus added', menus)),
    );
  }

  private toUpdate(menus: ToUpdate<MenuRaw>[]): Observable<MenuRaw[]> {
    if (menus.length === 0) {
      return of([]);
    }

    const menuFilesDownload$ = (menu: ToUpdate<MenuRaw>) => {
      const jobs = [];

      if (arrayDiff(menu.origin.pages, menu.value.pages).length > 0) {
        jobs.push(
          this.filesCache.bulkDelete(menu.origin.pages).pipe(
            switchMap(() => this.filesCache.downloadFiles(menu.value.pages)),
          ),
        );
      }

      if (menu.origin.icon !== menu.value.icon) {
        if (menu.origin.icon) {
          jobs.push(this.filesCache.delete(menu.origin.icon));
        }

        if (menu.value.icon) {
          jobs.push(this.filesCache.downloadFile(menu.value.icon));
        }
      }

      return jobs.length > 0 ? forkJoin(jobs) : of([]);
    };

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to update', menus)),
      switchMap((menus) => forkJoin(
        menus.map((menu) => {
          return menuFilesDownload$(menu).pipe(
            switchMap(() => this.menuRepository.update$(menu.value))
          )
        }),
      )),
      map(() => menus.map(({ value }) => value)),
      tap((menus) => this.logger.debug('Menus updated', menus)),
    );
  }

  private toDelete(menus: MenuRaw[]): Observable<unknown> {
    if (menus.length === 0) {
      return of([]);
    }

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to delete', menus)),
      switchMap((menus) => {
        return this.filesCache.bulkDelete(
          menus.map((menu) => {
            return (
              menu.icon ? [menu.icon, ...menu.pages] : menu.pages
            );
          }).flat(),
        ).pipe(
          tap((data) => this.logger.debug('Files deleted from cache', data)),
          map(() => menus)
        )
      }),
      switchMap((menus) => from(menus).pipe(
        concatMap((menu) => {
          return this.menuRepository.delete$(menu.id);
        }),
      )),
    );
  }

}
