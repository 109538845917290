import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { fromEvent, interval, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-bp-more',
  templateUrl: './bp-more.component.html',
  styleUrls: ['./bp-more.component.scss'],
  imports: [
    TranslateModule,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':decrement', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('InOutAnimation', [
      transition(':leave', [
        animate('400ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpMoreComponent implements AfterViewInit {

  public readonly back = output();

  public readonly index = signal(0);
  public readonly isAnimating = signal(false);

  private readonly content = viewChild.required<ElementRef<HTMLDivElement>>('content');

  constructor(
    private readonly destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    fromEvent<TouchEvent>(this.content().nativeElement, 'touchstart', { passive: true }).subscribe(startEvent => {
      const startY = startEvent.changedTouches[0].screenY;

      const touchEndSubscription = fromEvent<TouchEvent>(
        this.content().nativeElement,
        'touchend',
        { passive: true },
      ).subscribe(endEvent => {
        const endY = endEvent.changedTouches[0].screenY;

        const difference = startY - endY;
        const direction = difference <= 20 && difference >= -20
          ? 'click'
          : difference > 20
            ? 'up'
            : 'down';

        this.checkDirection(direction);

        touchEndSubscription.unsubscribe();
      });
    });
    interval(5000).pipe(
      takeUntil(merge(
        fromEvent(this.content().nativeElement, 'touchmove', { passive: true }),
        fromEvent(this.content().nativeElement, 'mousemove', { passive: true }),
        fromEvent(this.content().nativeElement, 'click', { passive: true }),
      )),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      if (!this.isAnimating()) {
        this.getNext();
      }
    });
  }

  getPrev(): void {
    if (!this.isAnimating()) {
      this.isAnimating.set(true);
      this.index.update((index) => (
        index - 1 + 6
      ) % 6);
      setTimeout(() => this.isAnimating.set(false), 300);
    }
  }

  getNext(): void {
    if (!this.isAnimating()) {
      this.isAnimating.set(true);
      this.index.update((index) => (
        index + 1
      ) % 6);
      setTimeout(() => this.isAnimating.set(false), 300);
    }
  }

  shouldShowPrevious(index: number): boolean {
    const prevIndex = (
      this.index() - 1 + 6
    ) % 6;
    return index === prevIndex;
  }

  shouldShowNext(index: number): boolean {
    const nextIndex = (
      this.index() + 1
    ) % 6;
    return index === nextIndex;
  }

  getAnimationState(index: number): string {
    if (index === this.index()) {
      return 'active';
    }
    else if (index === (
      this.index() - 1 + 6
    ) % 6) {
      return 'previous';
    }
    else if (index === (
      this.index() + 1
    ) % 6) {
      return 'next';
    }
    else {
      return '';
    }
  }

  private checkDirection(direction: string): void {
    if (direction !== 'click') {
      direction === 'up'
        ? this.getNext()
        : this.getPrev();
    }
  }
}
