import { DestroyRef, Injectable } from '@angular/core';
import { of, shareReplay } from 'rxjs';
import { IDeviceWorkSchedule } from '../models';
import { Intercom } from './intercom.service';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { KVRepository } from '../repositories';
import { ClusterService } from './cluster.service';

const EmptySchedule: IDeviceWorkSchedule[] = [
  { active: false, day: 0, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 1, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 2, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 3, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 4, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 5, timeFrom: '00:00', timeTo: '00:00' },
  { active: false, day: 6, timeFrom: '00:00', timeTo: '00:00' },
];

@Injectable()
export class DeviceWorkScheduleService {

  private readonly storageKey = 'deviceWorkSchedule';
  private readonly logger = this.watchdog.tag('Device Work Schedule', 'cyan');

  private readonly schedule$ = this.kvRepository.one$(this.storageKey).pipe(
    map((data) => {
      if (data?.value) {
        return data.value as IDeviceWorkSchedule[];
      }

      return null;
    }),
    shareReplay(1),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly cluster: ClusterService,
    private readonly intercom: Intercom,
    private readonly kvRepository: KVRepository,
    private readonly watchdog: WatchdogService,
    private readonly webSocket: WebsocketService,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

    // Send the work schedule to the device
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.authorized$),
      switchMap((authorized) => {
        if (!authorized) {
          return of(null);
        }

        return this.schedule$;
      }),
      map((schedule) => schedule ?? EmptySchedule),
      tap((schedule) => {
        this.intercom.call('device.work_schedule', schedule);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Retry sending the work schedule to the device
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.intercom.messages$),
      filter((message) => message.event === 'device.work_schedule.failed'),
      debounceTime(5000),
      switchMap(() => this.schedule$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((schedule) => {
      this.intercom.call('device.work_schedule', schedule ?? EmptySchedule);
    });

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'tableInfo'),
      tap((response) => {
        this.logger.info('Received: TableInfo message.', response);

        if (response.data.deviceWorkSchedule) {
          this.update(response.data.deviceWorkSchedule);
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public update(schedule: IDeviceWorkSchedule[]): void {
    this.schedule$.pipe(
      take(1),
      switchMap((scheduleLocal) => {
        const newSchedule = EmptySchedule.map((defaultDay) => {
          const newDay = schedule.find((day) => day.day === defaultDay.day);

          return {
            day: defaultDay.day,
            active: newDay?.active ?? defaultDay.active,
            timeFrom: (newDay?.timeFrom ?? defaultDay.timeFrom).split(':').slice(0, 2).join(':'),
            timeTo: (newDay?.timeTo ?? defaultDay.timeTo).split(':').slice(0, 2).join(':'),
          };
        });

        if (!this.areSchedulesEqual(scheduleLocal, newSchedule)) {
          return this.kvRepository.update$({
            key: this.storageKey,
            value: newSchedule,
          });
        } else {
          return of(newSchedule);
        }

      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private areSchedulesEqual(
    scheduleA: IDeviceWorkSchedule[] | null,
    scheduleB: IDeviceWorkSchedule[]
  ): boolean {
    if (!scheduleA || scheduleA.length !== scheduleB.length) {
      return false;
    }

    return scheduleA.every((a, index) => {
      const b = scheduleB[index];
      return (
        a.day === b.day &&
        a.active === b.active &&
        a.timeFrom === b.timeFrom &&
        a.timeTo === b.timeTo
      );
    });
  }

}
