import { Component, ElementRef } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  HappyHoursService,
  Menu,
  MenuImageComponent,
  MenusService,
  MenuWithIcon,
  UserActivityClickTrackingDirective,
} from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { BehaviorSubject, combineLatest, shareReplay, take } from 'rxjs';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-menus-tab',
  templateUrl: 'menus-tab.component.html',
  styleUrl: 'menus-tab.component.scss',
  imports: [
    TranslateModule,
    LoadingComponent,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
    RouterLink,
    RouterLinkActive,
    MenuImageComponent,
  ]
})
export class MenusTabComponent {

  public readonly selectedMenuId$ = new BehaviorSubject<number | undefined>(undefined);

  public readonly menus$ =  combineLatest([
    this.menusService.menusWithIcon$,
    this.happyHoursService.isNow$,
  ]).pipe(
    map(([menus, isNow]) => {
        if (menus === undefined) return undefined;

        return this.prepare(menus, isNow);
      }
    ),
    shareReplay(1)
  );

  public readonly menusWithPages$ = combineLatest([
    this.menusService.menusWithPages$,
    this.happyHoursService.isNow$,
  ]).pipe(
    map(([menus, isNow]) => {
      if (menus === undefined) return undefined;

      return this.prepare(menus, isNow);
    }),
    shareReplay(1)
  );

  public readonly currentMenu$ = this.selectedMenuId$.pipe(
    switchMap((selectedMenuId) =>
      this.menusWithPages$.pipe(
        take(1),
        map((menus) => {
          if (menus === undefined) return undefined;

          const navigationState = history.state;
          if (selectedMenuId === undefined) {
            if (navigationState && navigationState.tabHH) {
              return menus.find((menu: Menu) => menu.happyHours);
            }
            return menus[0];
          }

          return menus.find((menu: Menu) => menu.id === selectedMenuId);
        })
      )
    ),
    tap((menu) => {
      if (menu) {
        this.router.navigate(['main/menu', menu.id]).then();
        this.element.nativeElement.scroll({
          top: 0,
          left: 0,
        });
      }
    }),
    shareReplay(1)
  );

  public menus = toSignal(this.menus$);
  public isLoading = toSignal(this.menusService.loading$, {
    requireSync: true
  });
  public isSyncInProgress = toSignal(this.menusService.sync$, {
    requireSync: true
  });
  public currentMenu = toSignal(this.currentMenu$);

  constructor(
    private readonly element: ElementRef,
    private readonly menusService: MenusService,
    private readonly happyHoursService: HappyHoursService,
    private readonly router: Router,
  ) {}

  private prepare<T extends Menu | MenuWithIcon>(menus: T[], isNow: boolean): T[] {
    return menus.filter((menu: T) => !menu.hidden).sort(
      (a, b) => {

        if (isNow) {
          if (a.happyHours && !b.happyHours) return -1;
          if (!a.happyHours && b.happyHours) return 1;
        } else {
          if (a.happyHours && !b.happyHours) return 1;
          if (!a.happyHours && b.happyHours) return -1;
        }

        return a.id - b.id;
      })
  }
}
