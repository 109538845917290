import { DestroyRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, interval, Observable, of, shareReplay } from 'rxjs';
import { WatchdogService } from './watchdog.service';
import { Intercom } from './intercom.service';
import { WidgetUiConfigRef } from './widget-ui-config.ref';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { RestaurantTableService } from './restaurant-table.service';
import { TranslateService } from '@ngx-translate/core';
import { RGBA } from 'color-blend/dist/types';
import { IRestaurantTable } from '../models';
import { ClusterService } from './cluster.service';
import { NetworkService } from './network.service';
import { CORE_FEATURE_TOGGLE, CoreFeatureToggle } from '../../core.tokens';

export type CallWaiterStatus = {
  main: boolean;
  requestBill: boolean;
  anotherRound: boolean;
}

type CallWaiterObservableValue = {
  table: IRestaurantTable;
  status: CallWaiterStatus;
}

@Injectable()
export class CallWaiterService {

  private readonly logger = this.watchdog.tag('Call Waiter', 'cyan');

  private readonly mainSubject = new BehaviorSubject<boolean>(false);
  private readonly requestBillSubject = new BehaviorSubject<boolean>(false);
  private readonly anotherRoundSubject = new BehaviorSubject<boolean>(false);

  private readonly mainCount = new BehaviorSubject<number>(0);
  private readonly requestBillCount = new BehaviorSubject<number>(0);
  private readonly anotherRoundCount = new BehaviorSubject<number>(0);

  private readonly mainLastCallAt = new BehaviorSubject<Date | null>(null);
  private readonly requestBillLastCallAt = new BehaviorSubject<Date | null>(null);
  private readonly anotherRoundLastCallAt = new BehaviorSubject<Date | null>(null);

  public readonly mainStatus$ = this.mainSubject.asObservable().pipe(
    distinctUntilChanged(),
  );

  public readonly requestBillStatus$ = this.requestBillSubject.asObservable().pipe(
    distinctUntilChanged(),
  );

  public readonly anotherRoundStatus$ = this.anotherRoundSubject.asObservable().pipe(
    distinctUntilChanged(),
  );

  public readonly status$ = combineLatest([
    this.mainStatus$,
    this.requestBillStatus$,
    this.anotherRoundStatus$,
  ]).pipe(
    map(([main, requestBill, anotherRound]): CallWaiterStatus => {
      return { main, requestBill, anotherRound };
    }),
    shareReplay(1),
  );

  public readonly mainCount$ = this.mainCount.asObservable();
  public readonly requestBillCount$ = this.requestBillCount.asObservable();
  public readonly anotherRoundCount$ = this.anotherRoundCount.asObservable();

  public readonly count$ = combineLatest([
    this.mainCount$,
    this.requestBillCount$,
    this.anotherRoundCount$,
  ]).pipe(
    map(([main, requestBill, anotherRound]) => {
      return main + requestBill + anotherRound;
    }),
    shareReplay(1),
  );

  public readonly mainLastCallAt$ = this.mainLastCallAt.asObservable();
  public readonly requestBillLastCallAt$ = this.requestBillLastCallAt.asObservable();
  public readonly anotherRoundLastCallAt$ = this.anotherRoundLastCallAt.asObservable();

  public readonly lastCallAt$ = combineLatest([
    this.mainLastCallAt$,
    this.requestBillLastCallAt$,
    this.anotherRoundLastCallAt$,
  ]).pipe(
    map(([main, requestBill, anotherRound]) => {
      return [main, requestBill, anotherRound].filter((date) => date !== null);
    }),
    map((dates) => {
      if (dates.length === 0) {
        return null;
      }

      return new Date(Math.max(...dates.map((date) => date!.getTime())));
    }),
    shareReplay(1),
  );

  public readonly withMain$ = this.withCallWaiterByType('main');
  public readonly withRequestBill$ = this.withCallWaiterByType('requestBill');
  public readonly withAnotherRound$ = this.withCallWaiterByType('anotherRound');

  public readonly with$ = combineLatest([
    this.withMain$,
    this.withRequestBill$,
    this.withAnotherRound$,
  ]).pipe(
    map(([main, requestBill, anotherRound]) => {
      return { main, requestBill, anotherRound };
    }),
    shareReplay(1),
  );

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly translate: TranslateService,
    private readonly cluster: ClusterService,
    private readonly watchdog: WatchdogService,
    private readonly network: NetworkService,
    private readonly intercom: Intercom,
    private readonly widgetUiConfig: WidgetUiConfigRef,
    private readonly webSocket: WebsocketService,
    private readonly restaurantTable: RestaurantTableService,
  ) {
    this.mainStatus$.pipe(
      distinctUntilChanged(),
      filter((status) => status),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.mainCount.next(this.mainCount.value + 1);
      this.mainLastCallAt.next(new Date());
    });

    this.requestBillStatus$.pipe(
      distinctUntilChanged(),
      filter((status) => status),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.requestBillCount.next(this.requestBillCount.value + 1);
      this.requestBillLastCallAt.next(new Date());
    });

    this.anotherRoundStatus$.pipe(
      distinctUntilChanged(),
      filter((status) => status),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.anotherRoundCount.next(this.anotherRoundCount.value + 1);
      this.anotherRoundLastCallAt.next(new Date());
    });
  }

  get widgetUi(): WidgetUiConfigRef {
    return this.widgetUiConfig;
  }

  public initialize(): void {
    this.logger.info('Initialize');

    this.cluster.leader$.pipe(
      switchMap(() => this.with$),
      distinctUntilChanged(),
      switchMap((enabled) => {
        return this.status$.pipe(
          take(1),
          map((status) => {
            return {
              status,
              enabled,
            };
          }),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ status, enabled }) => {
      if (status.main && !enabled.main) {
        this.setMain(false);
      }

      if (status.requestBill && !enabled.requestBill) {
        this.setRequestBill(false);
      }

      if (status.anotherRound && !enabled.anotherRound) {
        this.setAnotherRound(false);
      }
    });

    this.cluster.messages$.pipe(
      filter((message) => message.type === 'waiter.call'),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      if (message.type === 'waiter.call') {
        switch (message.data.type) {
          case 'main':
            this.mainSubject.next(message.data.status);
            break;

          case 'requestBill':
            this.requestBillSubject.next(message.data.status);
            break;

          case 'anotherRound':
            this.anotherRoundSubject.next(message.data.status);
            break;
        }
      }
    });

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.status$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.sendAmbientLight(status);
    });

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((message) => message.type === 'echo'),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      if (message.data.status === false) {
        this.logger.debug('Received echo: Cancel Call Waiter.', message.data);
        this.setMain(false);
      }

      if (message.data.pay === false) {
        this.logger.debug('Received echo: Cancel Call Waiter To Pay.', message.data);
        this.setRequestBill(false);
      }

      if (message.data.repeat === false) {
        this.logger.debug('Received echo: Cancel Call Waiter To Repeat.', message.data);
        this.setAnotherRound(false);
      }
    });

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => combineLatest([
        this.restaurantTable.table$,
        this.webSocket.status$,
        this.status$,
      ])),
      map(([table, connected, status]) => {
        if (table === null) {
          return null;
        }

        if (connected === false) {
          return null;
        }

        return {
          table,
          status
        } satisfies CallWaiterObservableValue;
      }),
      filter((data): data is CallWaiterObservableValue => data !== null),
      tap(({ table, status }) => {
        this.logger.debug('Current States:', {
          table: table.tableId,
          status
        });

        this.sendStatus(table, status);
        this.sendPushNotification(table, status);
      }),
      switchMap(({ table, status }) => {

        if (status.main || status.requestBill || status.anotherRound) {
          return interval(5000).pipe(
            tap(() => this.sendStatus(table, status))
          );
        }

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public setMain(status: boolean): void {
    this.logger.info(status ? 'Call Waiter' : 'Cancel Call Waiter');
    this.cluster.broadcast('waiter.call', { type: 'main', status }).then();
    this.mainSubject.next(status);
  }

  public setRequestBill(status: boolean): void {
    this.logger.info(status ? 'Request Bill' : 'Cancel Request Bill');
    this.cluster.broadcast('waiter.call', { type: 'requestBill', status }).then();
    this.requestBillSubject.next(status);
  }

  public setAnotherRound(status: boolean): void {
    this.logger.info(status ? 'Another Round' : 'Cancel Another Round');
    this.cluster.broadcast('waiter.call', { type: 'anotherRound', status }).then();
    this.anotherRoundSubject.next(status);
  }

  private sendStatus(table: IRestaurantTable, status: CallWaiterStatus): void {
    this.webSocket.send('echo', {
      tableId: table.tableId,
      status: status.main,
      pay: status.requestBill,
      repeat: status.anotherRound,
    });
  }

  private sendPushNotification(table: IRestaurantTable, status: CallWaiterStatus): void {
    this.translate.getTranslation(this.translate.defaultLang).pipe(
      map((data) => data.pushNotification),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((text) => {
      const messages: any = [];

      if (status.main) {
        messages.push(text.callWaiter);
      }

      if (status.requestBill) {
        messages.push(text.billPlease);
      }

      if (status.anotherRound) {
        messages.push(text.oneMoreRound);
      }

      if (messages.length > 0) {
        this.webSocket.send('pushNotification', {
          title: `Table ${ table.tableName }`,
          body: messages.join(', '),
          recipientsCategory: 'all',
        })
      }
    });
  }

  private sendAmbientLight(status: CallWaiterStatus): void {
    if (status.main || status.requestBill || status.anotherRound) {
      let color: RGBA | null = null;

      if (status.main && this.widgetUi.callWaiterButtonLightColor) {
        color = this.widgetUi.callWaiterButtonLightColor;
      }

      if (status.requestBill && this.widgetUi.requestBillButtonLightColor) {
        color = this.widgetUi.requestBillButtonLightColor;
      }

      if (status.anotherRound && this.widgetUi.anotherRoundButtonLightColor) {
        color = this.widgetUi.anotherRoundButtonLightColor;
      }

      if (color) {
        this.intercom.call('ambient_light.on', { color });
      }
    }
    else {
      this.intercom.call('ambient_light.off');
    }
  }

  private withCallWaiterByType(type: 'main' | 'requestBill' | 'anotherRound'): Observable<boolean> {
    return of({
      main: this.featureToggle.withCallWaiterMain,
      requestBill: this.featureToggle.withCallWaiterRequestBill,
      anotherRound: this.featureToggle.withCallWaiterAnotherRound,
    }[type]).pipe(
      switchMap((enable) => {
        if (!enable) {
          return of(false);
        }

        return this.widgetUiConfig.config$.pipe(
          map((config) => {
            return {
              main: config.callWaiterButtons.callWaiter.enabled,
              requestBill: config.callWaiterButtons.requestBill.enabled,
              anotherRound: config.callWaiterButtons.anotherRound.enabled,
            }[type];
          }),
          switchMap((enabled) => {
            if (!enabled) {
              return of(false);
            }

            if (this.featureToggle.withCallWaiterOnOffline) {
              return of(true);
            }

            return this.network.status$;
          }),
        );
      }),
      shareReplay(1),
    );
  }

}
